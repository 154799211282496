import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import LayoutNew from "../components/LayoutNew";
import SafeHtmlParser from "../components/SafeHtmlParser";

const CookiePage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
						company
					}
				}
				pageData: wpPage(slug: { eq: "cookie-notice" }) {
					privacyPolicy {
						privacyPolicyTitle
						privacyPolicyContent
					}
					seoFields {
						title
						opengraphTitle
						opengraphDescription
						metaDescription
						localBusinessSchema
						image {
							altText
							sourceUrl
							mediaDetails {
								height
								width
							}
						}
					}
				}
			}
		`
	);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		pageData: { seoFields, privacyPolicy },
	} = data;

	return (
		<>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(seoFields?.localBusinessSchema)}
				</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.sourceUrl}`,
							width: `${seoFields?.image?.mediaDetails.width}`,
							height: `${seoFields?.image?.mediaDetails.height}`,
							alt: `${seoFields?.image?.altText}`,
						},
					],
				}}
			/>
			<LayoutNew>
				<section
					className="py-5 py-md-8"
					style={{ minHeight: "calc(100vh - 220px)" }}
				>
					<Container>
						<h1>{privacyPolicy.privacyPolicyTitle}</h1>

						<div className="text-light-black blog-post-content pt-3 mb-0">
							<SafeHtmlParser
								htmlContent={privacyPolicy.privacyPolicyContent}
							/>
						</div>
					</Container>
				</section>
			</LayoutNew>
		</>
	);
};
export default CookiePage;
